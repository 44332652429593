<template>
  <div class="yun-page">
    <div class="yun-box">
      <div class="center-box">
        <el-tabs v-model="TabActive"
                 @tab-click="TabActiveChange">
          <!-- 我的云服务 -->
          <el-tab-pane label="我的云服务"
                       name="first">
            <el-alert title="为确保功能正常使用，请及时购买云服务或续费云服务。"
                      type="warning"
                      show-icon>
            </el-alert>
            <el-row style="margin-top:10px;">
              <el-col :span="12">
                <!-- 分页 -->
                <el-pagination class="top-pagination"
                               background
                               layout="total"
                               :total="MyYunTotalNum">
                </el-pagination>
              </el-col>
              <el-col :span="12">
                <div class="func-btn">
                  <el-button type="base"
                             size="small"
                             @click="setXuFeiList('mult')">批量续费</el-button>
                  <el-button type="base"
                             size="small"
                             @click="TabActive = 'second'">购买云服务</el-button>
                </div>
              </el-col>
            </el-row>
            <el-table class="el-table-list"
                      :data="MyYunList"
                      v-loading="TabLoading"
                      :header-cell-style="{background:'#FAFAFA ',color:'#444'}"
                      size="medium"
                      @selection-change="IpSelect"
                      style="margin-top:8px;">
              <el-table-column width="60"
                               type="selection">
              </el-table-column>
              <el-table-column label="云服务"
                               prop="OutIp">
              </el-table-column>
              <el-table-column label="归属地"
                               prop="Attribution">
              </el-table-column>
              <el-table-column label="到期时间"
                               prop="ExpireTime">
              </el-table-column>
              <el-table-column label="创建时间"
                               prop="CreateTime">
              </el-table-column>
              <el-table-column label="绑定店铺名"
                               prop="ShopName">
              </el-table-column>
              <el-table-column label="操作"
                               width="130">
                <template slot-scope="{row}">
                  <div class="caozuo-btn">
                    <el-link type="primary"
                             :underline="false"
                             @click="setXuFeiList('single',row)">续费</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- 购买云服务 -->
          <el-tab-pane label="购买云服务"
                       name="second">

            <div class="buy-banner">
              <img src="../assets/images/pc/buybanner.png"
                   alt="">
            </div>
            <div class="buy-box">
              <div class="yunbao-box">
                <div><img src="../assets/images/pc/yunbao.png"
                       alt=""></div>
                <div class="yunbao-dec">
                  <div>{{PayConfig.ProductName}}</div>
                  <div>限时优惠</div>
                </div>
              </div>

              <div class="buy-form">
                <div class="lab-item">*选择平台</div>
                <el-select v-model="BuyYunForm.Platform"
                           style="width:300px"
                           placeholder="请选择平台">
                  <el-option v-for="item in PlatformOption"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
                <div class="lab-item">选择时长</div>
                <div style="overflow: hidden;">
                  <div :class="['buy-time',PayTimeActive == item.DurationTime?'buy-active':'']"
                       v-for="(item,index) in PayConfig.ProductConfigs"
                       :key="index"
                       @click="BuTimeChange('buy',item)">{{item.DurationTime}}个月</div>
                </div>
                <div class="lab-item">选择IP数量</div>
                <el-input v-model.number="BuyYunForm.Num"
                          style="width:100px"
                          @input="BuyNumChange"></el-input>
                <span class="give-count">( 赠送{{GiveNum}}个 ) </span>
                <div class="buy-end">
                  <div>总价：<span>¥{{PayPrice}}元</span></div>
                  <div>
                    <el-button type="base"
                               :loading="submitLoading"
                               @click="submitOrder('buy')">提交订单</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <!-- 续费云服务 -->
          <el-tab-pane label="续费云服务"
                       name="third">
            <div class="buy-box">
              <div class="yunbao-box">
                <div><img src="../assets/images/pc/yunbao.png"
                       alt=""></div>
                <div class="yunbao-dec">
                  <div>云服务器续费</div>
                  <div>限时优惠</div>
                </div>
              </div>
              <div class="choose-yun">
                <div>续费云服务器数：<span>{{XuFeiList.length}}</span>个 <span>(请到'我的云服务'选择对应的云服务进行续费)</span></div>
                <el-table :data="XuFeiList"
                          :header-cell-style="{background:'#FAFAFA ',color:'#444'}"
                          size="medium"
                          max-height="350"
                          style="margin-top:8px;">
                  <el-table-column label="云服务器IP"
                                   prop="OutIp">
                  </el-table-column>
                  <el-table-column label="地区"
                                   prop="Attribution">
                  </el-table-column>
                  <el-table-column label="过期时间"
                                   prop="ExpireTime">
                  </el-table-column>
                </el-table>
              </div>
              <div class="lab-item">续费时长</div>
              <div style="overflow: hidden;">
                <div :class="['buy-time',XuFeiTimeActive == item.DurationTime?'buy-active':'']"
                     v-for="(item,index) in XuFeiConfig.ProductConfigs"
                     :key="index"
                     @click="BuTimeChange('xufei',item)">{{item.DurationTime}}个月</div>
              </div>
              <div class="buy-end">
                <div>总价：<span>¥{{XuFeiPrice}}元</span></div>
                <div>
                  <el-button type="base"
                             :disabled="!XuFeiList.length"
                             :loading="submitLoading"
                             @click="submitOrder('xufei')">提交订单</el-button>
                </div>
              </div>
            </div>

          </el-tab-pane>
          <!-- 产品功能续费 -->
          <el-tab-pane label="产品功能续费"
                       name="fifth">
            <el-table class="el-table-list "
                      :data="ProductList"
                      :header-cell-style="{background:'#FAFAFA ',color:'#444'}"
                      size="medium"
                      height="490"
                      style="margin-top:8px;">
              <el-table-column label="产品功能"
                               prop="ProductName">
              </el-table-column>
              <el-table-column label="已绑店铺数"
                               prop="BindShopNum">
              </el-table-column>
              <el-table-column label="即将到期店铺数">
                <template slot-scope="{row}">
                  <div>
                    <el-link type="warning"
                             :underline="false"
                             @click="showDetailDialog(row,'3')">{{row.WellExpireNum}}</el-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="已到期店铺数">
                <template slot-scope="{row}">
                  <div>
                    <el-link type="danger"
                             :underline="false"
                             @click="showDetailDialog(row,'2')">{{row.isExpireNum}}</el-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作"
                               width="130">
                <template slot-scope="{row}">
                  <div class="caozuo-btn">
                    <el-link type="primary"
                             :underline="false"
                             @click="showDetailDialog(row)">详情</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- 我的订单 -->
          <el-tab-pane label="我的订单"
                       name="fourth">
            <el-form :inline="true"
                     :model="MyOrderForm"
                     class="demo-form-inline search-form">
              <el-form-item label="创建时间">
                <el-date-picker v-model="MyOrderSearchTime"
                                style="width:230px"
                                size="small"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format='yyyy-MM-dd'
                                :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="产品名称">
                <el-select style="width:130px;"
                           v-model="MyOrderForm.ProductType"
                           size="small">
                  <el-option v-for="item in ProductTypeOption"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单状态">
                <el-select style="width:130px;"
                           v-model="MyOrderForm.Status"
                           size="small">
                  <el-option v-for="item in StatusOption"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="交易单号">
                <el-input v-model.trim="MyOrderForm.TradeNo"
                          placeholder="请输入交易单号"
                          style="width:160px;"
                          size="small"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="base"
                           size="small"
                           :loading="TabLoading"
                           @click="MyOrderForm.PageIndex = 1;getMyOrderList()">查询</el-button>
                <el-button icon="el-icon-refresh"
                           size="small"
                           @click="resetMyOrderForm()">重置</el-button>
              </el-form-item>
            </el-form>
            <el-row>
              <el-col :span="12">
                <!-- 分页 -->
                <el-pagination class="top-pagination"
                               @size-change="MyOrderSizeChange"
                               @current-change="MyOrderCurrentChange"
                               :current-page="SearchForm.PageIndex"
                               :page-sizes="[20,40,60,100,200]"
                               :page-size="MyOrderForm.PageSize"
                               background
                               layout="total,prev, pager, next,sizes"
                               :total="MyOrderTotalNum">
                </el-pagination>
              </el-col>
            </el-row>
            <el-table class="el-table-list"
                      :data="MyOrderList"
                      v-loading="TabLoading"
                      :header-cell-style="{background:'#FAFAFA ',color:'#444'}"
                      size="medium"
                      max-height="500"
                      style="margin-top:8px;">
              <!-- <el-table-column label="时间"
                               width="240">
                <template slot-scope="{row}">
                  <div>
                    <div>创建时间：{{row.CreateTime}}</div>
                    <div>交易时间：{{row.PayTime}}</div>
                  </div>
                </template>
              </el-table-column> -->
              <!-- <el-table-column label="创建时间"
                               prop="CreateTime">
              </el-table-column> -->
              <el-table-column label="交易时间"
                               prop="PayTime">
              </el-table-column>
              <el-table-column label="交易订单号"
                               prop="TradeNo">
              </el-table-column>
              <el-table-column label="交易金额(元)"
                               prop="Amount">
              </el-table-column>
              <el-table-column label="商品名"
                               prop="BuyDetail.ProductName">
              </el-table-column>
              <el-table-column label="数量"
                               prop="BuyDetail.ProductNum">
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="{row}">
                  <div class="status-box">
                    <div v-if="row.Status == 1"
                         class="pay-warning">待付款</div>
                    <div v-if="row.Status == 2"
                         class="pay-success">已付款</div>
                    <div v-if="row.Status == 3"
                         class="pay-faild">付款超时</div>
                    <div v-if="row.Status == 5"
                         class="pay-faild">交易失败</div>
                    <div v-else-if="row.Status == 4|| row.Status == 400 || row.Status == 401"
                         class="pay-success">交易成功</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作"
                               width="100">
                <template slot-scope="{row}">
                  <div class="caozuo-btn">
                    <el-link v-if="row.Status == 1"
                             type="primary"
                             :underline="false"
                             @click="GoAheadpay(row.PayPage)">继续付款</el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 待支付 dialog -->
    <el-dialog :visible.sync="PayLoadingVisible"
               :close-on-click-modal="false"
               @closed="PayLoadingClosed"
               width="400px">
      <div class="payloading-box">
        <div>
          <img src="../assets/images/pc/shijian.png" />
        </div>
        <div>正在支付中...</div>
        <div>支付剩余时间： <span>{{PayLoadingTime}}</span> </div>

        <div style="margin-top:30px">
          <el-button type="base"
                     size="medium"
                     @click="checkOrder">查看订单</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功 提示 -->
    <el-dialog title="提示"
               :visible.sync="PayVisible"
               width="400px">
      <div>
        <el-result icon="success"
                   title="支付成功"
                   :subTitle="BuyDetail.ProductName+'成功'">
          <template slot="extra">
            <el-button type="base"
                       size="medium"
                       @click="TabActive = 'fourth';PayVisible = false">查看订单</el-button>
          </template>
        </el-result>
      </div>
    </el-dialog>

    <!-- 详情 -->
    <el-dialog class="deital-dialog"
               title="详情"
               :close-on-click-modal="false"
               top="60px"
               width="900px"
               :visible.sync="DetailVisible">
      <el-form :inline="true"
               :model="DetailSearchForm"
               class="demo-form-inline search-form">
        <el-form-item label="状态">
          <el-select style="width:130px;"
                     v-model="DetailSearchForm.ExpireStatus"
                     size="small">
            <el-option v-for="item in ExpireStatusOption"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model.trim="DetailSearchForm.StoreName"
                    placeholder="请输入店铺名称"
                    size="small"
                    style="width:170px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="base"
                     size="small"
                     @click="filterDetailList()">查询</el-button>
          <el-button icon="el-icon-refresh"
                     size="small"
                     @click="resetDetailSearchForm()">重置</el-button>
          <el-button type="base"
                     size="small"
                     @click="showXFDialog('mult')">批量续费</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="TabDetailList"
                :header-cell-style="{background:'#eef1f6',color:'#909399'}"
                max-height="600"
                size="small"
                @selection-change="TabDetailListSelect"
                style="margin-top:8px;">
        <el-table-column width="40"
                         type="selection">
        </el-table-column>
        <el-table-column label="店铺名称"
                         prop="StoreName">
        </el-table-column>
        <el-table-column label="店铺ID"
                         prop="StoreId">
        </el-table-column>
        <el-table-column label="功能名称"
                         prop="ProductTypeText">
        </el-table-column>
        <el-table-column label="到期时间"
                         prop="ExpireTime">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{row}">
            <div>
              <div v-if="row.ExpireStatus == 1"
                   :style="row.ExpireStatus == 1?'color:#67C23A':''">正常</div>
              <div v-if="row.ExpireStatus == 2"
                   :style="row.ExpireStatus == 2?'color:#F56C6C':''">已到期</div>
              <div v-if="row.ExpireStatus == 3"
                   :style="row.ExpireStatus == 3?'color:#E6A23C':''">即将到期</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="100">
          <template slot-scope="{row}">
            <div class="caozuo-btn">
              <el-link type="primary"
                       :underline="false"
                       @click="showXFDialog('single',row)">续费</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 续费Dialog -->
      <el-dialog :title="GNType == 'single'?'续费':'批量续费'"
                 :visible.sync="GNVisible"
                 @closed="GNDialogClosed"
                 :close-on-click-modal="false"
                 append-to-body
                 width="550px">
        <div v-if="GNType == 'single'">
          <div class="xf-list">
            <div>店铺名称</div>
            <div>{{GNShopName}}</div>
          </div>
          <div class="xf-list">
            <div>产品名称</div>
            <div>{{GNName}}</div>
          </div>
          <div class="xf-list">
            <div>到期时间</div>
            <div>{{GNExpireTime}}</div>
          </div>
        </div>
        <div v-if="GNType == 'mult'">
          <div class="xf-tips">已选中{{this.DetailSelectList.length}}条记录，包含{{this.DetailSelectList.length}}个店铺</div>
          <div class="xf-list">
            <div>产品名称</div>
            <div>{{GNName}}</div>
          </div>
        </div>
        <div class="xf-active">
          <div>使用时长</div>
          <div>
            <div style="overflow: hidden;">
              <div :class="['xf-time',GNTimeActive == item.DurationTime?'buy-active':'']"
                   v-for="(item,index) in GNProductConfigs"
                   :key="index"
                   @click="BuTimeChange('gn',item)">{{item.DurationTime}}个月</div>
            </div>
          </div>
        </div>
        <div class="xf-footer">
          <div>总价：<span>{{GNPrice}}</span>元</div>
          <div>
            <el-button size="medium"
                       @click="GNVisible = false">取消</el-button>
            <el-button type="base"
                       size="medium"
                       :loading="submitLoading"
                       @click="submitOrder('gn')">立即支付</el-button>
          </div>
        </div>
      </el-dialog>

    </el-dialog>
  </div>
</template>
<script>
const moment = require('moment')
var Timer, Timer2
export default {
  data () {
    return {
      TabActive: 'first',  // first second third fourth fifth
      TabLoading: false,
      submitLoading: false,
      PayVisible: false,
      PayConfig: {},    // 产品 购买
      XuFeiConfig: {},  // 产品 续费
      BuyDetail: {},
      MyYunList: [],  // 我的云服务
      MyYunTotalNum: 0,
      SearchForm: {
        ChannelId: '-1',
        PageIndex: 1,
        PageSize: 20,
      },
      SearchTime: [
        moment().subtract('1', 'month').format('yyyy-MM-DD'),
        moment().format('yyyy-MM-DD')
      ],
      pickerOptions: {
        disabledDate (date) {
          return date.getTime() > moment().subtract(-1, 'days')
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const start = moment().format('yyyy-MM-DD')
            const end = moment().format('yyyy-MM-DD')
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const start = moment().subtract(1, 'days').format('yyyy-MM-DD')
            const end = moment().subtract(1, 'days').format('yyyy-MM-DD')
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三天',
          onClick (picker) {
            const start = moment().subtract(2, 'days').format('yyyy-MM-DD')
            const end = moment().format('yyyy-MM-DD')
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const start = moment().subtract(6, 'days').format('yyyy-MM-DD')
            const end = moment().format('yyyy-MM-DD')
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const start = moment().subtract(1, 'month').format('yyyy-MM-DD')
            const end = moment().format('yyyy-MM-DD')
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const start = moment().subtract(3, 'month').format('yyyy-MM-DD')
            const end = moment().format('yyyy-MM-DD')
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      SourcechannelOption: [
        { label: '是', value: '1' },
        { label: '否', value: '0' },
      ],
      // 购买云服务
      BuyYunForm: {
        Id: '',
        Platform: '',
        ProductId: '',
        Num: 1,
      },
      Danjia: 0,
      PayPrice: 0,  // 总价
      PayTimeActive: 1,
      GiveNum: 0,  // 赠送数量
      // 平台
      PlatformOption: [
        { label: '抖店', value: 'dy' },
        { label: '快手', value: 'ks' },
        { label: '淘宝', value: 'tb' },
        { label: '京东', value: 'jd' },
        { label: '拼多多', value: 'pdd' },
        { label: '小红书', value: 'xhs' },
        { label: '视频号', value: 'sph' },
        { label: '抖店达人', value: 'dddr' },
      ],
      // 续费
      XuFeiList: [],
      IpSelectList: [],
      XuFeiFormType: '',
      XuFeiForm: {
        Id: '',
        Ip: '',
        Ids: '',
        ProductId: '',
      },
      XuFeiPrice: 0,
      XuFeiTimeActive: 1,

      // 我的订单  
      MyOrderList: [],
      MyOrderTotalNum: 0,
      MyOrderForm: {
        TradeNo: '',
        ProductType: '-1',
        ProductName: '',
        Status: '-1',
        PageIndex: 1,
        PageSize: 20
      },
      MyOrderSearchTime: ['', ''],
      // 产品类型
      ProductTypeOption: [
        { label: '不限', value: '-1' },
        { label: '云服务器购买', value: '1' },
        { label: '云服务器续费', value: '2' },
      ],
      StatusOption: [
        { label: '不限', value: '-1' },
        { label: '待付款', value: '1' },
        { label: '已付款', value: '2' },
        { label: '付款超时', value: '3' },
        { label: '交易失败', value: '5' },
        { label: '交易成功', value: '200' },
      ],

      // 产品功能续费
      ProdctList: [],
      ProductList: [],
      DetailVisible: false,
      DetailList: [],
      TabDetailList: [],
      DetailSearchForm: {
        ProductType: 0,
        ExpireStatus: '-1',
        StoreName: '',
      },
      ExpireStatusOption: [ // 到期状态
        { label: '不限', value: '-1' },
        { label: '正常', value: '1' },
        { label: '已到期', value: '2' },
        { label: '即将到期', value: '3' },
      ],
      DetailSelectList: [], // 批量续费
      PayLoadingVisible: false,
      PayLoadingTime: '00:00:00',
      // 续费
      GNProductConfigs: [],
      GNVisible: false,
      GNType: '',
      GNForm: {
        Id: '',
        ProductId: '',
        Num: 1,
        ShopIds: ''
      },
      GNShopIds: [],
      GNPrice: '',
      GNSinglePrice: '',
      GNTimeActive: '',
      GNShopName: '',
      GNName: '',
      GNExpireTime: '',
    }
  },
  created () {
    this.getProductList()  // 产品列表
    const FromAppData = JSON.parse(window.sessionStorage.getItem('FromAppData'))
    if (FromAppData) {
      const row = {
        OutIp: FromAppData.OutIP,
        Attribution: FromAppData.Address,
        ExpireTime: FromAppData.ExpireTime,
      }
      this.setXuFeiList('single', row)
    }
  },
  mounted () {
    const TabActive = window.sessionStorage.getItem('tab')
    this.TabActive = TabActive ? TabActive : 'first'
  },
  methods: {
    TabActiveChange (tab) {
      window.sessionStorage.removeItem('tab')
      if (tab.name == 'first') {
        this.getMyYunList()
      }
      if (tab.name == 'fourth') {
        this.getMyOrderList()
      }
      if (tab.name == 'fifth') {
        this.getShopProdcutList()
      }
    },
    // -----------------  功能续费  ------------------------
    // 获取所有数据
    async getShopProdcutList () {
      const formData = {
        MerchatAccount: window.sessionStorage.getItem('userName'),
        ProductType: 0
      }
      this.TabLoading = true
      const { data: res } = await this.$axios.post('/api/DBB/StoreProductList', formData)
      this.TabLoading = false
      if (res.nResult != 1) return
      var TimeDate = 7 * 24 * 60 * 60  // 七天
      const arr = this.ProdctList.filter((item, index) => {
        return index > 1
      })
      arr.forEach(item => {
        item.BindShopNum = res.Data.filter(data => { return data.ProductType == item.ProductType }).length
        // 即将到期店铺数
        item.WellExpireNum = res.Data.filter(data => {
          const outTime = Number(moment(data.ExpireTime).format('X') - moment().format('X'))
          return (outTime < TimeDate && outTime > 0) && data.ProductType == item.ProductType
        }).length
        // 已到期店铺数
        item.isExpireNum = res.Data.filter(data => {
          return Number(moment(data.ExpireTime).format('X') - moment().format('X')) < 0 && data.ProductType == item.ProductType
        }).length
      })
      this.ProductList = arr
      // 处理数据
      const ProductTypeOption = arr.map(v => { return { value: v.ProductType, label: v.ProductName } })
      res.Data.forEach(item => {
        item.ProductTypeText = this.Valformat(ProductTypeOption, item.ProductType)
        item.ExpireTime = this.Timeformat(item.ExpireTime)
        // 状态
        const outTime = Number(moment(item.ExpireTime).format('X') - moment().format('X'))
        if (outTime < TimeDate && outTime > 0) {
          item.ExpireStatus = 3
        } else if (outTime < 0 || item.ExpireTime == '--') {
          item.ExpireStatus = 2
        } else {
          item.ExpireStatus = 1
        }
      })
      this.DetailList = res.Data
      this.filterDetailList()
    },
    filterDetailList () { // 过滤数据
      const dataList = JSON.parse(JSON.stringify(this.DetailList))
      const searchData = JSON.parse(JSON.stringify(this.DetailSearchForm))
      this.TabDetailList = fuzzyQuery(dataList, searchData)
      function fuzzyQuery (list, searchData) {
        // console.log(searchData)
        if (!searchData) return list
        var arr = []
        for (var i = 0; i < list.length; i++) {
          if (searchData.ExpireStatus == -1) {
            console.log()
            if (searchData.ProductType == list[i].ProductType && list[i].StoreName.indexOf(searchData.StoreName) >= 0) {
              arr.push(list[i])
            }
          } else {
            if (searchData.ProductType == list[i].ProductType && list[i].StoreName.indexOf(searchData.StoreName) >= 0 && searchData.ExpireStatus == list[i].ExpireStatus) {
              arr.push(list[i])
            }
          }
        }
        return arr
      }
    },
    showDetailDialog (row, ExpireStatus) {
      this.DetailSearchForm.ProductType = row.ProductType
      this.DetailSearchForm.ExpireStatus = ExpireStatus ? ExpireStatus : '-1'
      this.DetailVisible = true
      // 功能续费  默认值
      // console.log(row)
      this.GNProductConfigs = row.ProductConfigs
      this.GNForm.Id = row._id
      this.GNForm.ProductId = this.GNProductConfigs[0].Id
      this.GNTimeActive = this.GNProductConfigs[0].DurationTime
      this.GNSinglePrice = this.GNProductConfigs[0].Price  // 储存单价
      this.filterDetailList()
    },
    resetDetailSearchForm () {
      this.DetailSearchForm.ExpireStatus = '-1'
      this.DetailSearchForm.StoreName = ''
    },
    PayLoadingClosed () {
      this.PayLoadingVisible = false
      clearInterval(Timer2)
    },
    TabDetailListSelect (val) {
      this.DetailSelectList = val
    },
    showXFDialog (type, row) {
      this.GNType = type
      console.log(row)
      if (type == 'single') {
        this.GNShopIds = [row.StoreId]
        this.GNVisible = true
        this.GNShopName = row.StoreName
        this.GNName = row.ProductTypeText
        this.GNPrice = this.GNSinglePrice
        // { label: '正常', value: '1' },
        // { label: '已到期', value: '2' },
        // { label: '即将到期', value: '3' },
        this.rowGNExpireTime = row.ExpireTime
        this.rowExpireStatus = row.ExpireStatus
        if (row.ExpireStatus == 2) {
          this.GNExpireTime = moment().subtract(-this.GNTimeActive, 'month').format('YYYY-MM-DD HH:mm:ss')
        } else {
          this.GNExpireTime = moment(row.ExpireTime).subtract(-this.GNTimeActive, 'month').format('YYYY-MM-DD HH:mm:ss')
        }
        console.log(row)
      }
      if (type == 'mult') {
        if (!this.DetailSelectList.length) return this.$message.warning('请选择操作项~')
        this.GNVisible = true
        this.GNName = this.DetailSelectList[0].ProductTypeText
        this.GNPrice = this.GNSinglePrice * this.DetailSelectList.length
        const arr = []
        this.DetailSelectList.forEach(item => {
          arr.push(item.StoreId)
        })
        this.GNShopIds = arr
      }
    },
    GNDialogClosed () {
      this.GNTimeActive = this.GNProductConfigs[0].DurationTime
    },
    // -----------------  功能续费  ------------------------

    // 获取产品列表
    async getProductList () {
      const { data: res } = await this.$axios.post('/api/Product/GetProductList', this.RegisterForm)
      if (res.nResult != 1) return
      res.Data.forEach(item => {
        item.ProductConfigs.forEach(configs => {
          configs.Price = configs.Price / 100
        })
      })
      // 购买
      this.PayConfig = res.Data[0]
      console.log(this.PayConfig)
      this.Danjia = this.PayConfig.ProductConfigs[0].Price // 默认单价
      this.PayPrice = this.PayConfig.ProductConfigs[0].Price  // 默认金额
      this.PayTimeActive = this.PayConfig.ProductConfigs[0].DurationTime
      this.BuyYunForm.ProductId = this.PayConfig.ProductConfigs[0].Id
      this.BuyYunForm.Id = this.PayConfig._id
      // 续费
      this.XuFeiConfig = res.Data[1]
      // if (this.IpSelectList.length) {
      //   this.XuFeiPrice = this.XuFeiConfig.ProductConfigs[0].Price * this.IpSelectList.length
      // } else {
      this.XuFeiPrice = this.XuFeiConfig.ProductConfigs[0].Price
      // }
      this.XuFeiTimeActive = this.XuFeiConfig.ProductConfigs[0].DurationTime
      this.XuFeiForm.ProductId = this.XuFeiConfig.ProductConfigs[0].Id
      this.XuFeiForm.Id = this.XuFeiConfig._id

      this.ProdctList = res.Data
      this.getShopProdcutList()
      this.getMyYunList()
    },
    // 获取我的云服务
    async getMyYunList () {
      const { data: res } = await this.$axios.post('/api/DBB/GetDBBAllIpInfo')
      if (res.nResult != 1) return
      res.Data.forEach(item => {
        item.ExpireTime = this.Timeformat(item.ExpireTime)
      })
      this.MyYunList = res.Data
      this.MyYunTotalNum = res.Data.length
      this.getMyOrderList()
    },
    // 获取 我的订单
    async getMyOrderList () {
      const formData = JSON.parse(JSON.stringify(this.MyOrderForm))
      formData.BeginDate = this.MyOrderSearchTime ? this.MyOrderSearchTime[0] : ''
      formData.EndDate = this.MyOrderSearchTime ? this.MyOrderSearchTime[1] : ''
      this.TabLoading = true
      const { data: res } = await this.$axios.post('/api/Product/GetOrderList', formData)
      this.TabLoading = false
      if (res.nResult != 1) return
      res.Data.List.forEach(item => {
        item.Amount = item.Amount / 100
        item.PayTime = this.Timeformat(item.PayTime)
        // item.StatusText = this.Valformat(this.StatusOption, item.Status)
      })
      this.MyOrderList = res.Data.List
      this.MyOrderTotalNum = res.Data.Count
    },
    resetMyOrderForm () {
      this.MyOrderForm = {
        TradeNo: '',
        ProductType: '-1',
        ProductName: '',
        PageIndex: 1,
        PageSize: 20
      }
    },
    // 续费
    IpSelect (val) {
      this.IpSelectList = val
    },
    setXuFeiList (type, row) {
      this.XuFeiFormType = type
      if (type == 'mult') {
        if (!this.IpSelectList.length) return this.$message.warning('请选择操作项~')
        this.TabActive = 'third'
        this.XuFeiList = []
        const Ids = []
        this.IpSelectList.forEach(item => {
          const obj = {
            OutIp: item.OutIp,
            Attribution: item.Attribution,
            ExpireTime: item.ExpireTime,
          }
          Ids.push(item._id)
          this.XuFeiList.push(obj)
        })
        this.XuFeiTimeActive = this.XuFeiConfig.ProductConfigs[0].DurationTime
        if (this.XuFeiList.length) {
          this.XuFeiPrice = this.XuFeiConfig.ProductConfigs[0].Price * this.XuFeiList.length
        } else {
          this.XuFeiPrice = this.XuFeiConfig.ProductConfigs[0].Price
        }
        this.XuFeiForm.Ids = Ids.join(',')
      }
      if (type == 'single') {
        this.TabActive = 'third'
        this.XuFeiList = []
        const obj = {
          OutIp: row.OutIp,
          Attribution: row.Attribution,
          ExpireTime: row.ExpireTime,
        }
        this.XuFeiForm.Ip = row.OutIp   // 续费Ip
        this.XuFeiList = [obj]
      }
    },
    // 选择 购买时常
    BuTimeChange (type, row) {
      if (type == 'buy') {
        this.Danjia = row.Price
        this.PayPrice = row.Price * this.BuyYunForm.Num
        this.PayTimeActive = row.DurationTime
        this.BuyYunForm.ProductId = row.Id
      }
      if (type == 'xufei') {
        if (this.XuFeiList.length) {
          this.XuFeiPrice = row.Price * this.XuFeiList.length
        } else {
          this.XuFeiPrice = row.Price
        }
        this.XuFeiTimeActive = row.DurationTime
        this.XuFeiForm.ProductId = row.Id
      }
      if (type == 'gn') {
        this.GNTimeActive = row.DurationTime
        this.GNForm.ProductId = row.Id
        if (this.GNType == 'single') {
          this.GNPrice = row.Price
          if (this.rowExpireStatus == 2) {
            this.GNExpireTime = moment().subtract(-this.GNTimeActive, 'month').format('YYYY-MM-DD HH:mm:ss')
          } else {
            this.GNExpireTime = moment(this.rowGNExpireTime).subtract(-this.GNTimeActive, 'month').format('YYYY-MM-DD HH:mm:ss')
          }
        } else {
          this.GNPrice = row.Price * this.DetailSelectList.length
        }
      }
    },

    // 提交订单
    async submitOrder (type) {
      if (type == 'buy') {
        const formData = JSON.parse(JSON.stringify(this.BuyYunForm))
        if (!formData.Platform) return this.$message.warning('请选择平台~')
        if (formData.Num < 1) return this.$message.warning('请输入Ip数量~')

        this.submitLoading = true
        const { data: res } = await this.$axios.post('/api/Product/BuyProduct', formData)
        this.submitLoading = false
        if (res.nResult != 1) return
        // 跳转
        window.open(res.Data)
        // loading
        // 监听 支付状态
        Timer = setInterval(() => {
          this.getPayStatus(res.vcResult)
        }, 4000);
      }
      if (type == 'xufei') {
        const formData = JSON.parse(JSON.stringify(this.XuFeiForm))
        if (this.XuFeiFormType == 'single') {
          this.submitLoading = true
          const { data: res } = await this.$axios.post('/api/Product/RenewProduct', formData)
          this.submitLoading = false
          if (res.nResult != 1) return
          window.open(res.Data)
          Timer = setInterval(() => {
            this.getPayStatus(res.vcResult)
          }, 4000);
          window.sessionStorage.removeItem('FromAppData')
        } else {
          this.submitLoading = true
          const { data: res } = await this.$axios.post('/api/Product/RenewProduct2', formData)
          this.submitLoading = false
          if (res.nResult != 1) return
          window.open(res.Data)
          Timer = setInterval(() => {
            this.getPayStatus(res.vcResult)
          }, 4000);
        }

        this.resetXufeiForm()
      }
      if (type == 'gn') {
        const formData = JSON.parse(JSON.stringify(this.GNForm))
        formData.ShopIds = this.GNShopIds.join(',')
        this.submitLoading = true
        const data = { BuyProductModels: JSON.stringify([formData]) }
        const { data: res } = await this.$axios.post('/api/Product/BuyProduct2', data)
        this.submitLoading = false
        if (res.nResult != 1) return
        window.open(res.Data)
        Timer = setInterval(() => {
          this.getPayStatus(res.vcResult)
        }, 4000);
      }
      // 支付Loading
      this.PayLoadingVisible = true
      this.LoadingOutTime = Number(moment().format('X')) + 600
      Timer2 = setInterval(() => {
        this.PayLoadingTime = this.countTime()
      }, 1000);
    },
    resetXufeiForm () {
      this.XuFeiForm = {
        Id: '',
        Ip: '',
        Ids: '',
        ProductId: '',
      }
    },
    BuyNumChange (val) {

      var reg = /^\+?[1-9][0-9]*$/;
      if (reg.test(val)) {
        if (val >= 1) {
          this.PayPrice = val * this.Danjia
          // 赠送数量
          this.GiveNum = Math.floor(val / this.PayConfig.ActivityConfig.BuyCount)
        }
      }
    },
    // 监听 支付状态
    async getPayStatus (TradeNo) {
      const { data: res } = await this.$axios.post('/api/Product/GetOrderInfo', { TradeNo })
      // console.log('查询支付状态：'+ res.Data.Status)
      // 1待付款,2已付款,3付款超时,4交易成功,5交易失败 400交易成功 401交易成功
      this.BuyDetail = res.Data.BuyDetail
      // res.Data.Status = 2
      if (res.Data.Status != 1) {
        this.PayLoadingVisible = false
        clearInterval(Timer)
      }
      if (res.Data.Status == 2 || res.Data.Status == 4 || res.Data.Status == 400 || res.Data.Status == 401) {
        this.PayVisible = true
      }
    },
    // 时间格式化
    Timeformat (time, format) {
      if (moment(time).format('YYYY-MM-DD HH:mm:ss') == '0001-01-01 00:00:00') return '--'
      return format ? moment(time).format(format) : moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    // 倒计时
    countTime () {
      var time = Number(this.LoadingOutTime - moment().format('X'))
      if (time >= 0) {
        return '00:' + moment(time * 1000).format('mm:ss')
      } else {
        this.PayLoadingVisible = false
        clearInterval(Timer2)
      }
    },
    Valformat (arr, value) {
      arr.forEach(option => {
        value = value == option.value ? option.label : value
      })
      return value
    },
    // 查看订单
    checkOrder () {
      // console.log(res.Data)
      this.TabActive = 'fourth'
      this.DetailVisible = false
      this.PayLoadingVisible = false
      this.GNVisible = false
      this.MyOrderForm.PageIndex = 1
      this.getMyOrderList()
    },
    GoAheadpay (PayPage) {
      window.open(PayPage)
    },
    // 分页
    MyOrderSizeChange (val) {
      this.MyOrderForm.PageSize = val
      this.getMyOrderList()
    },
    MyOrderCurrentChange (val) {
      this.MyOrderForm.PageIndex = val
      this.getMyOrderList()
    }
  },
}
</script>

<style lang="less" scoped>
.give-count {
  margin-left: 15px;
  color: #f7763a;
  font-weight: bold;
  font-size: 14px;
}
.buy-banner {
  img {
    width: 100%;
  }
}
.payloading-box {
  padding: 30px 0;
  text-align: center;
  div:nth-child(1) {
    width: 72px;
    height: 72px;
    margin: 0 auto;
  }
  div:nth-child(2) {
    font-weight: 600;
    font-size: 18px;
    margin: 15px 0;
  }
  div:nth-child(3) {
    font-weight: 600;
    font-size: 14px;
    color: #898989;
    span {
      color: #000;
    }
  }
}
.xf-tips {
  height: 40px;
  line-height: 40px;
  background: #e8f5ee;
  color: #3cb371;
  padding: 0 20px;
}
.xf-list {
  font-size: 16px;
  border-bottom: 1px dashed #e8e8e8;
  padding: 15px 20px;
  > div:nth-child(1) {
    color: #898989;
  }
  > div:nth-child(2) {
    color: #000000;
    margin-top: 10px;
  }
}
.xf-active {
  padding: 15px 20px;
}
.xf-footer {
  border-top: 1px solid #e8e8e8;
  overflow: hidden;
  padding-top: 20px;
  padding-left: 20px;
  > div:nth-child(1) {
    float: left;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    span {
      font-size: 26px;
    }
  }
  > div:nth-child(2) {
    float: right;
  }
}

.yun-page {
  padding: 80px 0 30px 0;
}
.yun-box {
  background: #fff;
}
.el-table-list {
  min-height: 400px;
  margin-bottom: 30px;
}
.el-table {
  border: 1px solid #e8e8e8;
}
.func-btn {
  float: right;
}
.caozuo-btn {
  .el-link {
    margin: 8px 14px 0 0;
  }
}
.search-form {
  margin-top: 8px;
  .el-form-item {
    margin-bottom: 6px;
  }
}

// 购买云服务
.buy-box {
  width: 800px;
  .buy-form {
    padding: 24px 0;
  }
}
.lab-item {
  font-size: 16px;
  color: #898989;
  margin: 20px 0;
}
.yunbao-box {
  overflow: hidden;
  padding: 24px 0;
  border-bottom: 1px dashed #979797;
  > div {
    float: left;
  }
  > div:nth-child(1) {
    width: 64px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .yunbao-dec {
    margin-left: 24px;
    > div:nth-child(1) {
      margin: 4px 0;
      font-size: 24px;
      color: #000000;
      font-weight: 600;
    }
    > div:nth-child(2) {
      font-size: 14px;
      color: #898989;
      font-weight: 400;
    }
  }
}
.buy-time {
  width: 160px;
  height: 40px;
  font-size: 16px;
  color: #595959;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  text-align: center;
  float: left;
  margin-right: 14px;
  cursor: pointer;
}
.xf-time {
  width: 130px;
  height: 40px;
  font-size: 16px;
  color: #595959;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  text-align: center;
  float: left;
  margin-right: 14px;
  cursor: pointer;
  margin-top: 6px;
}
.buy-active {
  color: #3cb371 !important;
  background: #ebf9f1 !important;
  border: 1px solid #3cb371 !important;
}
.buy-end {
  border-top: 8px solid #f2fbf8;
  overflow: hidden;
  padding: 20px 0;
  margin-top: 20px;
  > div:nth-child(1) {
    float: left;
    font-size: 18px;
    color: #000;
    span {
      font-size: 32px;
      color: #3cb371;
    }
  }
  > div:nth-child(2) {
    float: right;
  }
}

.choose-yun {
  margin-top: 30px;
  > div:nth-child(1) {
    font-size: 14px;
    color: #000000;
    > span:nth-child(1) {
      color: #3cb371;
    }
    > span:nth-child(2) {
      color: #888;
    }
  }
}
.el-dialog__body {
  padding: 0 0 30px 0;
}

.status-box {
  position: relative;
  padding-left: 15px;
}
.pay-success::after {
  content: '';
  width: 6px;
  height: 6px;
  background: #3cb371;
  position: absolute;
  left: 0px;
  top: 10px;
  border-radius: 50%;
}
// .pay-faild {
//   color: #f5222d;
// }
.pay-faild::after {
  content: '';
  width: 6px;
  height: 6px;
  background: #f5222d;
  position: absolute;
  left: 0px;
  top: 10px;
  border-radius: 50%;
}
.pay-warning::after {
  content: '';
  width: 6px;
  height: 6px;
  background: #e6a23c;
  position: absolute;
  left: 0px;
  top: 10px;
  border-radius: 50%;
}

/deep/.deital-dialog {
  .el-dialog__body {
    padding: 0px 20px 30px 20px;
  }
}
</style>
